import React from 'react';
import { createRoot } from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import { StarProvider } from './components/StarContext';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <StarProvider>
      <App />
    </StarProvider>
  </BrowserRouter>
);


