import React from 'react'
import { useParams } from 'react-router-dom'

function Season2Page() {
    const{id}=useParams()
  return (
    <div>
      <h2>Season2Page</h2>
      <h3>{id} Серия</h3>
      <video controls>
        <source src={`http://localhost:3001/serias2/${id}`} type='video/mp4'/>
      </video>
    </div>
  )
}

export default Season2Page
