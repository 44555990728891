import React from 'react'
import { useParams } from 'react-router-dom'
import s from './vidModul/SesPage.module.css'
function Season1Page() {
    const{id}=useParams()
  return (
    <div className={s.main}>
      <h2>Season1Page</h2>
      <h3>{id} Серия</h3>
      <video controls >
        <source src={`https://www.eiforiya.site:3001/serias/${id}`} type='video/mp4'/>
      </video>
      {/* <video className={s.video} controls src={`http://localhost:3001/serias/${id}`} class="object-fit-scale" type='video/mp4'></video> */}
    </div>
  )
}

export default Season1Page
