import React from 'react';
import { Link } from 'react-router-dom';
import { useStars } from './StarContext';
import s from './module/Season2.module.css';

function Season1() {
  const { sez3 } = useStars();

  return (
    <div className={s.main}>
      <h2 className={s.hh}>1 Сезонs</h2>
      <div className="row">
        {sez3.season1.map((star, index) => (
          <div key={index} className="col-6 col-md-3 mb-4">
            <Link to={`/season_1/${star.episode}`} className={s.link}>
              <div className={s.card}>
                <img
                  className={s.cardImage}
                  //src={`http://localhost:3001/image/${star.image}`}
                  src={`https://www.eiforiya.site:3001/image/${star.image}`}
                  alt={`Seria ${star.episode}`}
                />
                <div className={s.cardBody}>
                  <h5 className={s.cardTitle}>{star.title}</h5>
                  <p className={s.cardDescription}>{star.description}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export { Season1 };

