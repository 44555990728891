import React from 'react';
/*import styles from './NewModule.module.css';*/
import s from './Reklama.module.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


function Reklama() {
   const fl= [
      {
        "episode": 1,
        "title":"Seria_1",
        "image": "scr_1.png",
        "description": "Описание 1 серии"
      },
      {
        "episode": 2,
        "title": "Seria_2",
        "image": "scr_2.png",
        "description": "Описание 2  серии"
      },
      {
        "episode": 3,
        "title": "Seria_3",
        "image": "scr_3.png",
        "description": "Описание 3 серии"
      },      {
        "episode": 4,
        "title": "Seria_4",
        "image": "scr_4.png",
        "description": "Описание 4 серии"
      },      {
        "episode": 5,
        "title": "Seria_5",
        "image": "scr_5.png",
        "description": "Описание 5 серии"
      },      {
        "episode": 6,
        "title": "Seria_6",
        "image": "scr_6.png",
        "description": "Описание 6 серии"
      },      {
        "episode": 7,
        "title": "Seria_7",
        "image": "scr_7.png",
        "description": "Описание 7 серии"
      },      {
        "episode": 8,
        "title": "Seria_8",
        "image": "scr_8.png",
        "description": "Описание 8 серии"
      }
    ]  
  
    return (
    <>
        {fl.map((item, index) => (
        <Card className="bg-dark text-white"  key={index} >
            <Card.Img variant="top" src={`http://localhost:3001/image/${item.image}`} />
            <Card.Body>
                <Card.Title>Seria {item.episode}</Card.Title>
                <Card.Text>
                Some quick exam ple text to build on the card title and make up the
                bulk of the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
            </Card.Body>
        </Card>
        ))}
    </>
    );
  }

export default Reklama
