import React from 'react';

const images = {
  'First slide': require('./image/Screenshot_1.png'),
  'Second slide': require('./image/Screenshot_2.png'),
  'Third slide': require('./image/Screenshot_3.png')
};

function Karuselimage({ text }) {
  const imageSrc = images[text];
  
  return (
    <img
      className="d-block w-100"
      src={imageSrc}
      alt={text}
    />
  );
}

export default Karuselimage;
